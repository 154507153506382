/* https://stackoverflow.com/questions/48319372/changing-font-family-of-all-material-uiversion-1-components */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Nunito";
    font-style: bold;
    font-weight: 700;
    src: url("../fonts/Nunito/Nunito-Bold.ttf") format("truetype");
}
