body {
  margin: 0;
  font-family: 'Nunito', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background: url("https://source.unsplash.com/collection/46838655/daily") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: lightgray;
}

/* Allow the container to take up a wider space on wider monitors */
@media (min-width: 1500px) {
  .container {
    max-width: 1300px;
  }
}

/* Allow logo to fit better on mobile */
@media screen and (max-width: 600px) {
  header{
    padding: 30px 0 0;
  }
  header img{
    width: 35%;
  }
}

::-webkit-input-placeholder {
   font-style: italic;
}